<template>
  <div class="home-page">
    <v-swiper
      v-if="$t.homeSwiper ? $t.homeSwiper.length : false"
      :loop="true"
      :list="$t.homeSwiper || []"
      :show-pagination="false"
      :show-navigation="false"
      wrapper-class=" h-477"
    >
      <template v-slot="slotProps">
        <div class="swiper-slide por txt-c">
          <img class="w-p100 h-p100 object-c" :src="slotProps.item.image1" />
          <h3
            class="fs-48 c-f w-p100 fw-b h-p100 dis-flex a-i-c j-c-c home-model1-title"
            v-html="slotProps.item.title"
            @click="toLink(slotProps.item.url)"
          ></h3>
        </div>
      </template>
    </v-swiper>
    <!-- 模块2 -->
    <div class="c-f pt-75 pr-40 pl-40 pb-60 home-model2" :style="mainModel2">
      <h3 class="fs-34 fw-b">
        <span>{{ $g("homeSlogan.title") }}</span>
        <p class="lh-0"><i class="dis-ib w-70 h-7 bg-main va-m"></i></p>
      </h3>
      <p class="fs-28 mt-30">{{ $g("homeSlogan.content") }}</p>
      <div class="mt-40 dis-flex">
        <span
          class="dis-flex plr-15 h-50 fs-24 a-i-c j-c-c bg-main"
          @click="toAboutus">
          {{ $g("homeSlogan.ext1") }}
          <img
            class="ml-10 w-23 h-11"
            src="/static/images/main/icon-right.png"
            alt=""
          />
        </span>
      </div>
      <div class="mt-50">
        <img class="w-100" :src="$g('homeSlogan.image1')" alt="">
        <p class="fs-24">{{$g('homeSlogan.ext2')}}</p>
      </div>
      
    </div>
    <!-- 模块3 -->
    <div class="mt-60 plr-40">
      <h3 class="fs-34 fw-b c-3 txt-c">
        <span>{{ $g('homeBusinessTitle.title') }}</span>
        <p class="lh-0"><i class="dis-ib w-70 h-7 bg-main va-m"></i></p>
      </h3>
      <ul class="mt-50">
        <li
          class="mb-15 bg-edf1f5 br-6 dis-flex a-i-c j-c-c pt-20 pr-30 pl-30 pb-10"
          v-for="(item, index) in $t.homeBusinessContent||[]"
          :key="index"
          @click="toBusiness(index)"
        >
          <img
            :style="{
              width: 42 / 75 + 'rem',
              height: 42 / 75 + 'rem',
            }"
            :src="item.image1"
            alt=""
          />
          <div class="mlr-30 flex-1">
            <h4 class="fs-26 c-3">{{ item.title }}</h4>
            <p class="fs-24 c-9">{{ item.content }}</p>
          </div>
          <span class="c-e0e2e5 fs-36 fw-b">0{{ index + 1 }}</span>
        </li>
      </ul>
    </div>
    <!-- 模块4 -->
    <div v-if="$g('homeNewTitle.ext2')!='1'" class="mt-50 h-640 home-model4" :style="mainModel4">
      <div class="dis-flex j-c-b pt-85 c-f pl-30 pr-30">
        <h3 class="fs-34 fw-b">
          <span>{{ $g('homeNewTitle.title') }}</span>
          <p class="lh-0"><i class="dis-ib w-70 h-7 bg-main va-m"></i></p>
        </h3>
        <span
          class="dis-flex plr-15 h-50 fs-24 a-i-c j-c-c bg-main"
          @click="toNew"
          >{{ $g('homeSlogan.ext1') }}
          <img
            class="ml-10 w-23 h-11"
            src="/static/images/main/icon-right.png"
            alt=""
        /></span>
      </div>
      <div class="plr-30">
        <v-swiper
          v-if="getSwiper() ? getSwiper().length : false"
          :loop="true"
          :list="getSwiper()"
          :show-pagination="false"
          :slides-perview="2"
          :slides-pergroup="2"
          :space-between="15"
          :show-navigation="false"
          wrapper-class="mt-50 h-400"
        >
          <template v-slot="slotProps">
            <div class="swiper-slide">
              <router-link :to="{ path: '/new/detail', query: { id: slotProps.item.id } }" class="home-model4-list">
                <div class="txt-l bg-f pb-20 br-t-30">
                  <img
                    class="w-p100 h-208 br-t-30 object-c"
                    :src="slotProps.item.image1"
                  />
                  <div class="plr-10">
                    <p class="c-6 fs-24">{{ slotProps.item.ext1 }}</p>
                    <h3
                      class="c-3 fs-28 dis-flex a-i-center j-c-center h-80 text-td-2 main-swiper-title"
                    >
                      {{ slotProps.item.title }}
                    </h3>
                    <div class="fs-24">
                      <span class="c-6">{{ slotProps.item.ext2 }}</span>
                      <p class="lh-0"><i class="dis-ib w-42 h-5 bg-6"></i></p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </template>
        </v-swiper>
      </div>
    </div>
    <!-- 模块5 -->
    <div class="mt-80">
      <div class="dis-flex j-c-b pl-40 pr-40 mb-50">
        <h3 class="fs-34 fw-b">
          <span>{{ $g('homePartnerTitle.title') }}</span>
          <p class="lh-0"><i class="dis-ib w-70 h-7 bg-main va-m"></i></p>
        </h3>
        <span
          class="dis-flex plr-15 h-50 c-f fs-24 a-i-c j-c-c bg-main"
          @click="toAboutus('friends')"
          >{{ $g('homePartnerTitle.ext1') }}
          <img
            class="ml-10 w-23 h-11"
            src="/static/images/main/icon-right.png"
            alt=""
        /></span>
      </div>
      <div class="pd-40">
        <v-swiper
          v-if="$t.homePartnerContent ? $t.homePartnerContent.length : false"
          :list="$t.homePartnerContent||[]"
          :navigation-next-el="function () {return $refs.next1;}"
          :navigation-prev-el="function () {return $refs.prev1;}"
          :slides-perview="4"
          :slides-pergroup="4"
          :slides-percolumn="3"
          :show-navigation="true"
          :show-pagination="false"
          :space-between="10"
          :autoplay="false"
          contain-class="h-360 flex-1 plr-30"
        >
          <template slot="navigation">
            <div class="home-model5-navigation">
              <img
                ref="prev1"
                class="dis-ib home-model5-prev"
                src="/static/images/main/swiper-left.png"
              />
              <img
                ref="next1"
                class="dis-ib home-model5-next"
                src="/static/images/main/swiper-right.png"
              />
            </div>
          </template>
          <template v-slot="slotProps">
            <div class="h-95 swiper-slide">
              <div class="w-p100">
                <img class="w-p100" :src="slotProps.item.image1" />
              </div>
            </div>
          </template>
        </v-swiper>
      </div>
    </div>
    <!-- 模块6 -->
    <div class="mt-50 plr-30 c-f h-375 dis-flex flex-column j-c-c home-model6" :style="mainModel6">
      <h3 class="fs-34 fw-b">
        <span>{{ $g('homeContact.title') }}</span>
        <p class="lh-0"><i class="dis-ib w-70 h-7 bg-main va-m"></i></p>
      </h3>
      <p class="fs-28 mt-20">{{$g('homeContact.content') }}</p>
    </div>
    <!-- 模块7 -->
    <ul class="mt-50 c-3 plr-40">
      <li
        class="pb-70 pt-15"
        v-for="(item, index) in $t.homeAddress||[]"
        :key="index"
      >
        <h3 class="fs-30 fw-b pb-10 por bb-3-252525">
          <span>{{ item.ext3 }}</span>
          <span class="c-fff5f5 fs-55 home-model7-ext2">{{ item.ext2 }}</span>
        </h3>
        <ul class="dis-flex fs-24 a-i-c j-c-b mt-10">
          <li class="flex-1 dis-flex flex-column j-c-b h-200 pb-40">
            <div class="dis-flex">
              <span class="dis-ib w-30 txt-c">
                <img
                  class="w-25 h-27 mt-5"
                  :src="$g('homeAddressIcon.image1')"
                  alt=""
                />
              </span>
              <span class="ml-20 dis-flex">
                <span>{{ item.title }}</span>
                <span class="flex-1">{{ item.ext4 }} </span>
              </span>
            </div>
            <div class="dis-flex">
              <span class="dis-ib w-30 txt-c">
                <img
                  class="w-30 h-21 mt-8"
                  :src="$g('homeAddressIcon.image2')"
                  alt=""
                />
              </span>
              <span class="ml-20 dis-flex">
                <span>{{item.content}}</span>
                <span class="flex-1">{{ item.ext5 }} </span>
              </span>
            </div>
          </li>
          <li class="txt-r">
            <img class="w-160" :src="item.image1" alt="" />
            <p>{{ item.image1Tip }}</p>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        autoplay: true,
      },
    };
  },
  computed: {
    mainModel2() {
      let me = this;
      return `
       background-image: url(${me.$g("homeSlogan.image2")});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
    mainModel4() {
      let me = this;
      return `
       background-image: url(${me.$g("homeNewTitle.image1")});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
    mainModel6() {
      let me = this;
      return `
       background-image: url(${me.$g("homeContact.image1")});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
  },
  methods: {
    getSwiper() {
      let me = this;
      let aArr = me.$a.newArticle || [];
      if (aArr.length > 10) {
        return aArr.slice(0, 10);
      }
      return aArr;
    },
    toLink(url) {
      location.href = url;
    },
    toAboutus(id) {
      if (id == "friends") {
        this.$router.push({
          path: "/aboutus",
          query: { id: id },
        });
      } else {
        this.$router.push("/aboutus");
      }
    },
    toBusiness(index) {
      this.$router.push({ path: `/business/detail/${index}` });
    },
    toNew() {
      this.$router.push("/new");
    },
  },
};
</script>
<style  lang="scss">
.home-model1-title {
  position: absolute;
  left: 0;
  top: 0;
}
.home-model2 {
  background: url(/static/images/main/model2-bg.jpg) no-repeat center;
  background-size: cover;
}
.home-model4 {
  background: url(/static/images/main/model4-bg.jpg) no-repeat center;
  background-size: cover;
}
.home-model4-list:active .main-swiper-title,
.home-model4-list:active p,
.home-model4-list:active span {
  color: #b02125;
}
.home-model4-list:active i {
  background: #b02125;
}
.home-model5-prev,
.home-model5-next {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  z-index: 2;
}
.home-model5-prev {
  left: 0px;
}
.home-model5-next {
  right: 0px;
}
.home-model6 {
  background: url(/static/images/main/model6-bg.jpg) no-repeat center;
  background-size: cover;
}
.home-model7-ext2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
</style>
